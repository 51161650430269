import { Box, useBreakpointValue } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { FC } from 'react';
import { useTranslation } from '../lib/hooks.context';
import { mapNewsTeaserProps } from '../lib/resolvers';
import { MhyChakraProps, SelectedEntriesField } from '../types';
import ArrowLink from './ArrowLink';
import ArticleGridWrapper from './ArticleGridWrapper';
import { SectionHeader } from './BasicBlocks';

const Carousel = dynamic(() => import('./Carousel/Carousel'));
const TeaserSplit = dynamic(() => import('./TeaserSplit'));
const Teaser = dynamic(() => import('./Teaser'));

const RenderWrapper: FC<Pick<SelectedEntriesField, 'heading' | 'link'>> = ({
  heading,
  children,
  link,
  ...chakraProps
}) => {
  const t = useTranslation();

  return (
    <Box gridColumn={['1 / -1', null, '2']} as="section" {...chakraProps}>
      {heading && (
        <SectionHeader title={heading} pl={[5, null, 0]}>
          {link && (
            <ArrowLink href={link.url}>
              {link.title.includes('_') ? t(link.title) : link.title}
            </ArrowLink>
          )}
        </SectionHeader>
      )}
      {children}
    </Box>
  );
};

const SelectedEntries = ({
  entries = [],
  isOrderedContent,
  link,
  ...rest
}: SelectedEntriesField &
  Omit<MhyChakraProps, 'variant'> & { isOrderedContent?: boolean }) => {
  const isMobile = useBreakpointValue({ md: true });
  const t = useTranslation();

  if (entries.length < 1) {
    return null;
  }

  if (entries.length < 2) {
    return (
      <RenderWrapper link={link} {...rest}>
        {entries.map(
          (entry) =>
            entry && (
              <TeaserSplit
                key={entry.id}
                py={5}
                px={[5, null, 0]}
                {...mapNewsTeaserProps(entry, t, true)}
              />
            ),
        )}
      </RenderWrapper>
    );
  }

  return (
    <RenderWrapper link={link} {...rest}>
      {entries.length < 4 || !isMobile ? (
        <ArticleGridWrapper isOrderedContent={isOrderedContent}>
          {entries.map(
            (entry) =>
              entry && (
                <Teaser
                  key={`${entry.id}-${entry.site || 'main'}`}
                  {...mapNewsTeaserProps(entry, t, false, true)}
                  as="li"
                />
              ),
          )}
        </ArticleGridWrapper>
      ) : (
        <Carousel carouselType="teaser">
          {entries.map(
            (entry) =>
              entry && (
                <Teaser
                  key={`${entry.id}-${entry.site || 'main'}`}
                  {...mapNewsTeaserProps(entry, t, false, true)}
                />
              ),
          )}
        </Carousel>
      )}
    </RenderWrapper>
  );
};
export default SelectedEntries;
